import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../../components/dfa-theme/layout/hcp'
import BrandCTA from '../../../components/brand-cta'
import quarter from '../../../images/Implant_with_Quarter.png'


const metaTags = {
  description: 'Learn about once-yearly dosing with SUPPRELIN® LA (histrelin acetate) subcutaneous implant. View Important Safety Information.',
  keywords: 'Homepage',
  title: 'Once-yearly Dosing | SUPPRELIN® LA (histrelin acetate)'
}

const IndexPage = () => (
  <Layout meta={metaTags} hasReferences={true}>
    <Row>
      <Col xs={12}>
        <h1>A single implant provides a full year of continuous medication<sup>1</sup></h1>
        <h2><strong>Uninterrupted suppression</strong>: Implant technology is designed to continuously deliver <span className='block_nowrap'>~65 mcg</span> histrelin acetate each and every day, <strong>over 12 full months</strong>.<sup>1</sup> </h2>
        <h2>The implant is a small, thin, flexible tube measuring 3.5 cm by 3 mm.</h2>
      </Col>
    </Row>
    <Row center='xs'>
      <Col xs={12}>
        <img src={quarter} alt="Implant Size" title="Implant Size"/>
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <ul className='brand-list'>
          <li>
            The recommended dose is one 50-mg implant, surgically inserted subcutaneously into the inner aspect of the upper arm once every <span className='block_nowrap'>12 months</span>
          </li>
          <li>
            SUPPRELIN<sup>&reg;</sup> LA is designed to allow for a few additional weeks of histrelin acetate release, to allow for flexibility in scheduling appointments for administration
          </li>
          <li>
            Treatment with SUPPRELIN<sup>&reg;</sup> LA may reduce the number of administrations of a GnRHa
          </li>
          <li>
            LH, FSH, and estradiol or testosterone should be monitored at 1 month post-implantation, then every 6 months. Every 6-12 months, height and bone age should be assessed
          </li>
          <li>
            One month after removal, histrelin levels were undetectable and LH returned to pubertal levels (n=4)—demonstrating that the suppression obtained with SUPPRELIN<sup>&reg;</sup> LA was completely reversible in a clinical trial<sup>2</sup>
          </li>
          <li>
            Rare events of spontaneous extrusion of the implant have been observed in clinical trials
          </li>
        </ul>
        <p className='study-copy'>FSH=follicle-stimulating hormone.
          <br />GnRHa=gonadotropin-releasing hormone agonist.
            <br />LH=luteinizing hormone.
        </p>
      </Col>
    </Row>
    <Row between="xs">
      <Col xs={12} md={6}>
        <BrandCTA
          href={'/hcp/procedure'}
          LinkCaption="Learn more about your SUPPRELIN<sup>&reg;</sup> LA Implantation Kit - once yearly dosing"
        >
         Learn more about your SUPPRELIN<sup>&reg;</sup> LA Implantation Kit

        </BrandCTA>
      </Col>
      <Col xs={12} md={6}>
        <BrandCTA
          href="/hcp/caregiver-resources"
          LinkCaption="View videos designed to help your patients understand CPP and SUPPRELIN<sup>&reg;</sup> LA - once yearly dosing"
        >
          View videos designed to help your patients understand CPP and SUPPRELIN<sup>&reg;</sup> LA
        </BrandCTA>
      </Col>
    </Row>
  </Layout>
)

export default IndexPage
